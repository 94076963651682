import { HeadProps } from "gatsby";
import React from "react";
import FormPageWrapper from "../components/FormPageWrapper";
import Layout from "../components/Layout";
import Seo from "../features/seo/Seo";
import { useCurrentUserQuery } from "../features/user/userApiSlice";

const ManualApproval = () => {
  const { data: currentUser } = useCurrentUserQuery();
  return (
    <Layout>
      <FormPageWrapper>
        <p>
          Your payment has not yet been processed since it has been flagged by
          our payments processor, Paddle, for manual approval by the Paddle
          support team. They usually take 1-2 hours to investigate the payment,
          but it can take up to 12 hours. If you urgently need access, then
          please{" "}
          <a
            className="text-blue-700 underline hover:text-blue-600"
            href="https://paddle.net"
          >
            contact the Paddle support team
          </a>{" "}
          with the email address <b>{currentUser?.data?.mail}</b> used to make
          your purchase.
        </p>
      </FormPageWrapper>
    </Layout>
  );
};

export default ManualApproval;

export const Head = (props: HeadProps) => {
  return (
    <Seo
      title="Manual approval required | Giancoli Answers"
      pathname={props.location.pathname}
    />
  );
};
